import React from "react"
import { Row, Col } from "react-bootstrap"

const ImageDownloadSocial = ({
    root,
    filename,
    name,
    text,
    text2,
    linkedinCompany,
    linkedinProfile,
    fbgroup,
    fbpage,
    fbprofile,
    twitter,
}) => {
    return (
        <Row className="mt-5">
            <Col md={6}>
                <img
                    src={`${root}/${filename}.png`}
                    className="img-fluid"
                    alt=""
                    style={{ maxHeight: "350px" }}
                />
            </Col>
            <Col>
                <h3>{name}</h3>
                <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                    {text}
                </p>
                <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                    {text2}
                </p>
                {linkedinCompany && (

                    <a
                    href={linkedinCompany}
                    className="btn btn-primary m-3"
                    target="_blank"
                    >
                    LI Company
                </a>
                )}
                {linkedinProfile && (
                    <a
                        href={linkedinProfile}
                        className="btn btn-primary m-3"
                        target="_blank"
                    >
                    LI Profile
                    </a>
                )}
                 {fbgroup && (
                    <a
                        href={fbgroup}
                        className="btn btn-primary m-3"
                        target="_blank"
                    >
                    FB Group
                    </a>
                )}
                {fbpage && (
                    <a
                        href={fbpage}
                        className="btn btn-primary m-3"
                        target="_blank"
                    >
                    FB Page
                    </a>
                )}
                 {fbprofile && (
                    <a
                        href={fbprofile}
                        className="btn btn-primary m-3"
                        target="_blank"
                    >
                    FB Profile
                    </a>
                )}
                {twitter && (
                    <a
                        href={twitter}
                        className="btn btn-primary m-3"
                        target="_blank"
                    >
                    Twitter
                    </a>
                )}
                
                
            </Col>
        </Row>
    )
}

export default ImageDownloadSocial
